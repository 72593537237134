import { ROAD_SIMPLE_WHITE } from 'Colors/mapbox'

export const MAPOSAIC_PALETTES = {
  '247ba070c1b3b2dbbff3ffbdff1654': {
    colors: ['#247ba0', '#70c1b3', '#b2dbbf', '#f3ffbd', '#ff1654'],
    specificTransforms: { [ROAD_SIMPLE_WHITE]: { color: '#003d5b', isEditable: true, name: 'roads' } },
  },
  '05668d02809000a89602c39af0f3bd': {
    colors: ['#05668d', '#028090', '#00a896', '#02c39a', '#f0f3bd'],
    specificTransforms: null,
  },
  '2646532a9d8fe9c46af4a261e76f51': {
    colors: ['#264653', '#2a9d8f', '#e9c46a', '#f4a261', '#e76f51'],
    specificTransforms: null,
  },
  '50514ff25f5cffe066247ba070c1b3': {
    colors: ['#50514f', '#f25f5c', '#ffe066', '#247ba0', '#70c1b3'],
    specificTransforms: null,
  },
  ef476fffd16606d6a0118ab2073b4c: {
    colors: ['#ef476f', '#ffd166', '#06d6a0', '#118ab2', '#073b4c'],
    specificTransforms: null,
  },
  bce7845dd39e348aa7525174513b56: {
    colors: ['#bce784', '#5dd39e', '#348aa7', '#525174', '#513b56'],
    specificTransforms: null,
  },
  '114b5f1a936f88d498c6dabff3e9d2': {
    colors: ['#114b5f', '#1a936f', '#88d498', '#c6dabf', '#f3e9d2'],
    specificTransforms: null,
  },
  '7bdff2b2f7efeff7f6f7d6e0f2b5d4': {
    colors: ['#7bdff2', '#b2f7ef', '#eff7f6', '#f7d6e0', '#f2b5d4'],
    specificTransforms: null,
  },
  ffb997f67e7d843b620b032d74546a: {
    colors: ['#ffb997', '#f67e7d', '#843b62', '#0b032d', '#74546a'],
    specificTransforms: null,
  },
  c9cba3ffe1a8e26d5c723d46472d30: {
    colors: ['#c9cba3', '#ffe1a8', '#e26d5c', '#723d46', '#472d30'],
    specificTransforms: null,
  },
  ffa69efaf3ddb8f2e6aed9e05e6472: {
    colors: ['#ffa69e', '#faf3dd', '#b8f2e6', '#aed9e0', '#5e6472'],
    specificTransforms: null,
  },
  '540d6eee4266ffd23f3bceac0ead69': {
    colors: ['#540d6e', '#ee4266', '#ffd23f', '#3bceac', '#0ead69'],
    specificTransforms: null,
  },
  '1b998b2d3047fffd82ff9b71e84855': {
    colors: ['#1b998b', '#2d3047', '#fffd82', '#ff9b71', '#e84855'],
    specificTransforms: null,
  },
  b8d8bad9dbbcfcddbcef959d69585f: {
    colors: ['#b8d8ba', '#d9dbbc', '#fcddbc', '#ef959d', '#69585f'],
    specificTransforms: null,
  },
  f8ffe506d6a01b9aaaef476fffc43d: {
    colors: ['#f8ffe5', '#06d6a0', '#1b9aaa', '#ef476f', '#ffc43d'],
    specificTransforms: null,
  },
  ffac81ff928bfec3a6efe9aecdeac0: {
    colors: ['#ffac81', '#ff928b', '#fec3a6', '#efe9ae', '#cdeac0'],
    specificTransforms: null,
  },
  '0b132b1c25413a506b5bc0beffffff': {
    colors: ['#0b132b', '#1c2541', '#3a506b', '#5bc0be', '#ffffff'],
    specificTransforms: null,
  },
  f7b267f79d65f4845ff27059f25c54: {
    colors: ['#f7b267', '#f79d65', '#f4845f', '#f27059', '#f25c54'],
    specificTransforms: null,
  },
  '2319425e548e9f86c0be95c4e0b1cb': {
    colors: ['#231942', '#5e548e', '#9f86c0', '#be95c4', '#e0b1cb'],
    specificTransforms: null,
  },
  '26547cef476fffd16606d6a0fffcf9': {
    colors: ['#26547c', '#ef476f', '#ffd166', '#06d6a0', '#fffcf9'],
    specificTransforms: null,
  },
  '03256c2541b21768ac06bee1ffffff': {
    colors: ['#03256c', '#2541b2', '#1768ac', '#06bee1', '#ffffff'],
    specificTransforms: null,
  },
  '64a6bd90a8c3ada7c9d7b9d5f4cae0': {
    colors: ['#64a6bd', '#90a8c3', '#ada7c9', '#d7b9d5', '#f4cae0'],
    specificTransforms: null,
  },
  '26547cef476fffd16606d6a0fcfcfc': {
    colors: ['#26547c', '#ef476f', '#ffd166', '#06d6a0', '#fcfcfc'],
    specificTransforms: null,
  },
  ffe74cff59646bf17835a7ff: {
    colors: ['#ffe74c', '#ff5964', '#6bf178', '#35a7ff'],
    specificTransforms: null,
  },
  '5d737e64b6acc0fdfbdaffeffcfffd': {
    colors: ['#5d737e', '#64b6ac', '#c0fdfb', '#daffef', '#fcfffd'],
    specificTransforms: null,
  },
  c9e4ca87bba255828b3b6064364958: {
    colors: ['#c9e4ca', '#87bba2', '#55828b', '#3b6064', '#364958'],
    specificTransforms: null,
  },
  fe5f55f0b67fd6d1b1c7efcfeef5db: {
    colors: ['#fe5f55', '#f0b67f', '#d6d1b1', '#c7efcf', '#eef5db'],
    specificTransforms: null,
  },
  ff595effca3a8ac9261982c46a4c93: {
    colors: ['#ff595e', '#ffca3a', '#8ac926', '#1982c4', '#6a4c93'],
    specificTransforms: null,
  },
  '1a1423372549774c60b75d69eacdc2': {
    colors: ['#1a1423', '#372549', '#774c60', '#b75d69', '#eacdc2'],
    specificTransforms: { [ROAD_SIMPLE_WHITE]: { color: '#1a1422', isEditable: true, name: 'roads' } },
  },
  b0d0d3c08497f7af9df7e3aff3eec3: {
    colors: ['#b0d0d3', '#c08497', '#f7af9d', '#f7e3af', '#f3eec3'],
    specificTransforms: { [ROAD_SIMPLE_WHITE]: { color: '#1a1422', isEditable: true, name: 'roads' } },
  },
  f9dbbdffa5abda627da53860450920: {
    colors: ['#f9dbbd', '#ffa5ab', '#da627d', '#a53860', '#450920'],
    specificTransforms: { [ROAD_SIMPLE_WHITE]: { color: '#f9dbbe', isEditable: true, name: 'roads' } },
  },
  cc5803e2711dff9505ffb627ffc971: {
    colors: ['#cc5803', '#e2711d', '#ff9505', '#ffb627', '#ffc971'],
    specificTransforms: { [ROAD_SIMPLE_WHITE]: { color: '#f9dbbe', isEditable: true, name: 'roads' } },
  },
  '11151c212d403641567d4e57d66853': {
    colors: ['#11151c', '#212d40', '#364156', '#7d4e57', '#d66853'],
    specificTransforms: null,
  },
  fdc5f5f7aef8b388eb8093f172ddf7: {
    colors: ['#fdc5f5', '#f7aef8', '#b388eb', '#8093f1', '#72ddf7'],
    specificTransforms: null,
  },
  ffc09fffee93fcf5c7a0ced9adf7b6: {
    colors: ['#ffc09f', '#ffee93', '#fcf5c7', '#a0ced9', '#adf7b6'],
    specificTransforms: null,
  },
  ff99c8fcf6bdd0f4dea9def9e4c1f9: {
    colors: ['#ff99c8', '#fcf6bd', '#d0f4de', '#a9def9', '#e4c1f9'],
    specificTransforms: null,
  },
  edae49d1495b00798c30638e003d5b: {
    colors: ['#edae49', '#d1495b', '#00798c', '#30638e', '#003d5b'],
    specificTransforms: { [ROAD_SIMPLE_WHITE]: { color: '#003d5b', isEditable: true, name: 'roads' } },
  },
  efc7c2ffe5d4bfd3c168a691694f5d: {
    colors: ['#efc7c2', '#ffe5d4', '#bfd3c1', '#68a691', '#694f5d'],
    specificTransforms: { [ROAD_SIMPLE_WHITE]: { color: '#694f5e', isEditable: true, name: 'roads' } },
  },
  efd9cedec0f1b79ced957fef7161ef: {
    colors: ['#efd9ce', '#dec0f1', '#b79ced', '#957fef', '#7161ef'],
    specificTransforms: { [ROAD_SIMPLE_WHITE]: { color: '#22222F', isEditable: true, name: 'roads' } },
  },
  '97f9f9a4def9c1e0f7cfbae1c59fc9': {
    colors: ['#97f9f9', '#a4def9', '#c1e0f7', '#cfbae1', '#c59fc9'],
    specificTransforms: { [ROAD_SIMPLE_WHITE]: { color: '#31383D', isEditable: true, name: 'roads' } },
  },
  f9c80ef86624ea3546662e9b43bccd: {
    colors: ['#f9c80e', '#f86624', '#ea3546', '#662e9b', '#43bccd'],
    specificTransforms: null,
  },
  '2fc05bfe2c03fedb044587c6a8d0e8211913ff8b9d5eadd5': {
    colors: ['#2fc05b', '#fe2c03', '#fedb04', '#4587c6', '#a8d0e8', '#211913', '#ff8b9d', '#5eadd5'],
    specificTransforms: null,
  },
  cc444bda5552df7373e39695e4b1ab: {
    colors: ['#cc444b', '#da5552', '#df7373', '#e39695', '#e4b1ab'],
    specificTransforms: null,
  },
  f7f4eaded9e2c0b9dd80a1d475c9c8: {
    colors: ['#f7f4ea', '#ded9e2', '#c0b9dd', '#80a1d4', '#75c9c8'],
    specificTransforms: null,
  },
  ee605560d394aaf683ffd97dff9b85: {
    colors: ['#ee6055', '#60d394', '#aaf683', '#ffd97d', '#ff9b85'],
    specificTransforms: null,
  },
  faa275ff8c61ce6a859852775c374c: {
    colors: ['#faa275', '#ff8c61', '#ce6a85', '#985277', '#5c374c'],
    specificTransforms: null,
  },
  '4d9de0e15554e1bc293bb2737768ae': {
    colors: ['#4d9de0', '#e15554', '#e1bc29', '#3bb273', '#7768ae'],
    specificTransforms: null,
  },
  '6b717eefaac4ffc4d1ffe8e1d4dccd': {
    colors: ['#6b717e', '#efaac4', '#ffc4d1', '#ffe8e1', '#d4dccd'],
    specificTransforms: null,
  },
  '70d6ffff70a6ff9770ffd670e9ff70': {
    colors: ['#70d6ff', '#ff70a6', '#ff9770', '#ffd670', '#e9ff70'],
    specificTransforms: null,
  },
  baf2bbbaf2d8bad7f2f2bac9f2e2ba: {
    colors: ['#baf2bb', '#baf2d8', '#bad7f2', '#f2bac9', '#f2e2ba'],
    specificTransforms: null,
  },
  '00a6fb0582ca006494003554051923': {
    colors: ['#00a6fb', '#0582ca', '#006494', '#003554', '#051923'],
    specificTransforms: { [ROAD_SIMPLE_WHITE]: { color: '#ffffff', isEditable: true, name: 'roads' } },
  },
  '0000000c18211b2a41324a5fccc9dc': {
    colors: ['#000000', '#0c1821', '#1b2a41', '#324a5f', '#ccc9dc'],
    specificTransforms: { [ROAD_SIMPLE_WHITE]: { color: '#ccc9dd', isEditable: true, name: 'roads' } },
  },
  ee635259cd903fa7d6fac05ef79d84: {
    colors: ['#ee6352', '#59cd90', '#3fa7d6', '#fac05e', '#f79d84'],
    specificTransforms: null,
  },
  ffb8d1e4b4c2e7cee3e0e1e9ddfdfe: {
    colors: ['#ffb8d1', '#e4b4c2', '#e7cee3', '#e0e1e9', '#ddfdfe'],
    specificTransforms: null,
  },
  '3772fff038ffef709de2ef7070e4ef': {
    colors: ['#3772ff', '#f038ff', '#ef709d', '#e2ef70', '#70e4ef'],
    specificTransforms: null,
  },
  '63474daa767cd6a184ffa686fec196': {
    colors: ['#63474d', '#aa767c', '#d6a184', '#ffa686', '#fec196'],
    specificTransforms: null,
  },
  '031d4404395e70a288dab785d5896f': {
    colors: ['#031d44', '#04395e', '#70a288', '#dab785', '#d5896f'],
    specificTransforms: { [ROAD_SIMPLE_WHITE]: { color: '#04395f', isEditable: true, name: 'roads' } },
  },
  F04358FFD26205D5AA107FB107364C: {
    colors: ['#F04358', '#FFD262', '#05D5AA', '#107FB1', '#07364C'],
    specificTransforms: null,
  },
}
